.form {
    display: flex;
    justify-content: space-between;
    column-gap: .977vw;
}

.fieldset {
    border: none;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.field {
    display: flex;
    flex-direction: column;
}

.input {
    min-width: 20.996vw;
    min-height: 2.441vw;
    padding: .488vw .977vw;
    border: none;
    border-radius: 10px;
    background-color: var(--text-secondary-color);
    font-family: var(--text-font-family);
    font-size: .781vw;
    line-height: 1.074vw;
}

::placeholder {
    color: var(--form-placeholder-text-color);
    opacity: .4;
    font-family: var(--text-font-family);
    font-size: .781vw;
    line-height: 1.074vw;
}

.textarea {
    min-width: 20.996vw;
    min-height: 5.859vw;
    padding: .488vw .977vw;
    border: none;
    border-radius: 10px;
    resize: none;
    background-color: var(--text-secondary-color);
    font-family: var(--text-font-family);
    font-size: .781vw;
    line-height: 1.074vw;
}

.controls {
    display: flex;
    flex-direction: column;
    gap: .977vw;
    width: 100%;
}

.buttonsContainer {
    display: flex;
    gap: .977vw;
}

.error {
    outline: 1px solid var(--form-error-color);
}

.errorContainer {
    min-height: .977vw;
}

.errorMessage {
    font-family: var(--text-font-family);
    color: var(--form-error-color);
    font-size: .586vw;
    padding: 0 .977vw;
    display: flex;
}


.stateContainer {
    width: 100%;
    min-height: 2.441vw;
}

.stateErrorMessage {
    font-family: var(--text-font-family);
    color: var(--form-error-color);
    font-size: .586vw;

}

.stateSuccessMessage {
    font-family: var(--text-font-family);
    color: var(--form-success-color);
    font-size: .586vw;
    padding-top: .488vw;
}

@media screen and (max-width: 1536px) {
    ::placeholder, .input, .textarea {
        font-size: 14px;
        line-height: 16px;
        min-width: 100%;
    }

    .errorMessage, .stateErrorMessage, .stateSuccessMessage {
        font-size: 12px;
        line-height: 14px;
    }
}

@media screen and (max-width: 1279px) {
    .form {
        flex-direction: column;
    }

    .input {
        min-width: 100%;
        min-height: 40px;
        padding: 10px 20px;
        font-size: 16px;
        line-height: 20px;
    }

    ::placeholder {
        font-size: 16px;
        line-height: 20px;
    }

    .textarea {
        min-width: 100%;
        min-height: 90px;
        padding: 10px 20px;
        font-size: 16px;
        line-height: 20px;
    }

    .controls {
        gap: 20px;
    }

    .buttonsContainer {
        gap: 20px;
    }

    .buttonsContainer button:first-of-type {
        width: auto;
    }

    .errorContainer {
        min-height: 20px;
    }

    .errorMessage {
        font-size: 12px;
    }

    .stateContainer {
        min-height: 50px;
    }

    .stateErrorMessage {
        padding-top: 10px;
        font-size: 12px;
    }

    .stateSuccessMessage {
        padding-top: 10px;
        font-size: 12px;
    }
}

