.Logo {
    width: 4.883vw;
    height: 4.883vw;
    background: url(../../../images/logo.svg) center center no-repeat;
    background-size: contain;
    display: flex;
    flex-shrink: 0;
}

.inactive {
    pointer-events: none;
}

@media screen and (max-width: 1279px) {
    .Logo {
        width: 50px;
        height: 50px;
    }
}