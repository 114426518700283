.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .75);
    z-index: 999;
}

.active {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    padding: 2.441vw 2.441vw 0;
    background: var(--secondary-bg-color);
    border-radius: 10px;
    position: relative;
    max-width: 47.852vw;
}

.title {
    font-family: var(--heading-font-family);
    color: var(--text-main-color);
    font-weight: 900;
    font-size: 1.074vw;
    line-height: 1.514vw;
    text-transform: uppercase;
    padding-bottom: 1.465vw;
    user-select: none;
}

.close {
    border: none;
    outline: none;
    width: .977vw;
    height: .977vw;
    background: url(../../../images/modal-icon-close.svg) no-repeat center center transparent;
    cursor: pointer;
    position: absolute;
    right: .977vw;
    top: .977vw;
    background-size: contain;
}

@media screen and (max-width: 1536px) {
    .content {
        min-width: 750px;
    }
    .title {
        font-size: 18px;
        line-height: 21px;
    }
}

@media screen and (max-width: 1279px) {
    .content {
        width: 100%;
        max-width: calc(100% - 40px);
        min-width: calc(100% - 40px);
        padding: 50px 20px 0;
    }

    .close {
        width: 20px;
        height: 20px;
        top: 20px;
        right: 20px;
    }

    .title {
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 20px;
    }
}