@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/OpenSans-Regular.woff) format('woff');
    font-display: block;

}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url(../fonts/OpenSans-SemiBold.woff) format('woff');
    font-display: block;

}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/OpenSans-Bold.woff) format('woff');
    font-display: block;

}

@font-face {
    font-family: 'Arial Black';
    font-style: normal;
    font-weight: 900;
    src: url(../fonts/ArialBlack.woff) format('woff');
    font-display: block;

}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: var(--text-font-family);
}

body {
    background-color: var(--secondary-bg-color);
    -webkit-tap-highlight-color: transparent;
}

:root {
    --user-height: 100%;
    --header-bg-color: #d3cbab;
    --text-main-color: #000;
    --text-font-family: 'Open Sans', sans-serif;
    --heading-font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
    --button-border-radius: 10px;
    --primary-bg-color: #d9f600;
    --header-width: 17.334vw;
    --secondary-bg-color: #e5e5e5;
    --text-secondary-color: #fcfcf9;
    --form-placeholder-text-color: #1c1d1b;
    --form-error-color: #e74343;
    --form-success-color: #52ae27;
}

.in {
    animation: .3s in forwards;
}

.out {
    animation: .1s out forwards;
}

@keyframes in {
    from {
        transform: scale(1.1);
        opacity: .5;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes out {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1);
    }
}