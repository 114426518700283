.App {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    position: relative;
    background-color: var(--secondary-bg-color);
    overflow: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}

.container {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1279px) {

    .App {
        flex-direction: column;
        height: var(--user-height);
    }
    .container {
        overflow-y: scroll;
    }
}
