.Footer {
    min-height: 5.859vw;
    display: flex;
    gap: 11.475vw;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 1.221vw 0;
}

.nav {
    display: flex;
    gap: 4.639vw;
    justify-content: space-between;
}

.link {
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .781vw;
    line-height: 1.074vw;
    text-decoration: none;
}

.developer {
    opacity: .5;
    text-align: right;
}

@media screen and (max-width: 1536px) {
    .link {
        font-size: 13px;
        line-height: 15px;
    }
}

@media screen and (max-width: 1279px) {
    .Footer {
        width: 100%;
        flex-shrink: 0;
        padding: 30px 0 0;
        flex-wrap: wrap;
    }

    .nav {
        gap: 25px;
        flex-direction: column;
    }

    .link {
        font-size: 14px;
        line-height: 14px;
    }
}