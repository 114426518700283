.TextWithIcon {
    display: flex;
    gap: 1.221vw;
    align-items: flex-start;
    max-width: 21.484vw;
}

.icon {
    width: 1.465vw;
    height: 1.465vw;
    object-fit: contain;
    user-select: none;
}

.text {
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: .977vw;
}

@media screen and (max-width: 1536px) {
    .text {
        font-size: 16px;
        line-height: 21px;
    }

    .icon {
        width: 25px;
        height: 25px;
    }
}

@media screen and (max-width: 1279px) {
    .TextWithIcon {
        max-width: 100%;
        gap: 25px;
    }

    .icon {
        width: 30px;
        height: 30px;
    }

    .text {
        font-size: 16px;
        line-height: 21px;
        gap: 20px;
    }
}