.HeaderMobile {
    display: none;
}


@media screen and (max-width: 1279px) {
    .HeaderMobile {
        width: 100%;
        padding: 10px 20px;
        min-height: 70px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        background-color: var(--header-bg-color);
        align-items: center;
        align-content: start;
    }

    .container {
        display: flex;
        gap: 29%;
        align-items: center;
    }

    .buttonWrapper {
        width: 100%;
        max-width: 170px;
        grid-column: 2/4;
        margin: 0 auto;
    }

    .hamburger {
        border: none;
        outline: none;
        width: 20px;
        height: 20px;
        background: url(../../../images/header-mobile-icon-hamburger.svg) center center no-repeat transparent;
        cursor: pointer;
        background-size: contain;
        justify-self: end;
    }

    .close {
        border: none;
        outline: none;
        width: 20px;
        height: 20px;
        background: url(../../../images/modal-icon-close.svg) center center no-repeat transparent;
        cursor: pointer;
        background-size: contain;
        justify-self: end;
    }
}