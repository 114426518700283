.Product {
    width: calc(100% - var(--header-width));
    display: flex;
    gap: 2.197vw;
    height: 100%;
}

.container {
    padding: 2.930vw 2.197vw 0 0;
    display: flex;
    flex-direction: column;
    gap: 3.174vw;
    width: 100%;
}

.tabs {
    display: flex;
    list-style: none;
    gap: .488vw;
    justify-content: space-between;
}

.tabsContainer {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tabsContainer::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.tabName {
    background-color: var(--text-secondary-color);
    width: 100%;
    min-height: 2.441vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
    outline: none;
    font-size: .879vw;
    line-height: 1.221vw;
    font-family: var(--text-font-family);
    color: var(--text-main-color);
}

.tabActive {
    font-weight: 600;
    border-bottom: .146vw solid var(--text-main-color);
}

.tabContent {
    padding: 2.197vw 0 0;
}

.tabPrice {
    display: flex;
    flex-direction: column;
    gap: 2.441vw;
}

.cityAndPrice {
    display: flex;
    gap: 5vw;
}

.footnotes {
    list-style: none;
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
    max-width: 600px;
}

.info {
    padding-bottom: 10px;
}

.tabNav {
    display: flex;
}

.tabNavList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: .732vw;
    min-width: 17.090vw;
}

.tabNavName {
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
    cursor: pointer;
    user-select: none;
    outline: none;
}

.tabNavActive {
    font-family: var(--heading-font-family);
    color: var(--text-main-color);
    font-weight: 900;
    font-size: .977vw;
    line-height: 1.367vw;
    display: flex;
    gap: .732vw;
    align-items: center;
}

.tabNavActive::before {
    content: '';
    background: url(../../images/navlink-arrow.svg) no-repeat center center;
    background-size: contain;
    width: .732vw;
    height: .732vw;
}

.tabNavContent {
    padding-left: 1.465vw;
}

.use {
    display: grid;
    gap: 1.221vw 2.441vw;
    grid-template-columns: repeat(2, 1fr);

}

.warranty {
    max-width: 29.297vw;
}

.warrantyHeading {
    font-family: var(--heading-font-family);
    color: var(--text-main-color);
    font-weight: 900;
    font-size: 1.074vw;
    line-height: 1.221vw;
}

.warrantySubheading {
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
    padding-top: .977vw;
}

.stone {
    list-style: none;
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
    display: flex;
    flex-direction: column;
    gap: .977vw;
}

.price {
    font-size: .977vw;
    font-weight: 700;
}

@media screen and (max-width: 1536px) {
    .Product {
        width: 100%;
    }

    .tabNavName, .stone, .price, .tabName, .footnotes, .warrantySubheading {
        font-size: 16px;
        line-height: 21px;
    }

    .warrantyHeading {
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 1279px) {
    .Product {
        flex-direction: column;
        padding: 35px 20px 0;
        width: 100%;
        overflow-y: scroll;
    }

    .container {
        padding: 0;
        gap: 35px;
    }

    .container form {
        order: 3;
    }

    .tabsContainer {
        width: calc(100% + 40px);
        margin-left: -20px;
    }

    .tabs {
        overflow-x: scroll;
        gap: 10px;
    }

    .tabs::-webkit-scrollbar {
        display: none;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .tabActive {
        border-bottom: 2px solid var(--text-main-color);
    }

    .tabName {
        min-height: 40px;
        font-size: 16px;
        line-height: 20px;
        padding: 10px 25px;
    }

    .tabContent {
        padding: 30px 20px 0;
    }

    .tabPrice {
        gap: 30px;
    }

    .cityAndPrice {
        gap: 10px;
        flex-direction: column;
    }

    .footnotes {
        font-size: 15px;
        line-height: 21px;
    }

    .tabNav {
        flex-direction: column;
    }

    .tabNavList {
        gap: 15px;
        min-width: 100%;
    }

    .tabNavActive {
        font-size: 16px;
        line-height: 21px;
        gap: 15px;
    }

    .tabNavActive:before {
        width: 12px;
        height: 12px;
    }

    .tabNavName {
        font-size: 16px;
        line-height: 21px;
    }

    .tabNavContent {
        padding: 35px 20px 0 0;
    }

    .use {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
    }

    .warranty {
        max-width: 100%;
    }

    .warrantyHeading {
        font-size: 18px;
        line-height: 23px;
    }

    .warrantySubheading {
        font-size: 16px;
        line-height: 21px;
        padding-top: 20px;
    }

    .stone {
        font-size: 16px;
        line-height: 21px;
        gap: 20px;
    }

    .price {
        font-size: 18px;
        line-height: 25px;
    }
}