.link {
    width: 1.367vw;
    height: 1.367vw;
    text-decoration: none;
    color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    flex-shrink: 0;
    transition: opacity .3s ease-out;
}

.link:hover {
    opacity: .5;
}

.whatsapp {
    background-image: url(../../../images/home-whatsapp-icon.svg);
}

.vk {
    background-image: url(../../../images/home-vk-icon.svg);
    width: 1.563vw;
}

.telegram {
    background-image: url(../../../images/home-telegram-icon.svg);
}

@media screen and (max-width: 1536px) {
    .link {
        width: 25px;
        height: 25px;
    }

    .vk {
        width: 29px;
    }
}

@media screen and (max-width: 1279px) {
    .link {
        width: 22px;
        height: 22px;
    }

    .vk {
        width: 26px;
    }
}