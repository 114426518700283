.Contacts {
    height: 100%;
    min-height: calc(100vh - 90px);
    padding: 2.930vw 2.197vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper {
    display: flex;
    gap: 9.766vw;
    padding-bottom: 2.441vw;
}

.container {
    display: flex;
    flex-direction: column;
    gap: .977vw;
    max-width: 28.076vw;
}

.hours {
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
}

.map {
    width: 100%;
    height: 100%;
    min-height: 280px;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
    user-select: none;
}

.social {
    display: flex;
    gap: 1.465vw;
    align-items: center;
}

@media screen and (max-width: 1536px) {
    .hours {
        font-size: 16px;
        line-height: 21px;
    }
}

@media screen and (max-width: 1279px) {
    .Contacts {
        width: 100%;
        padding: 35px 20px 50px;
    }

    .container {
        max-width: 100%;
        gap: 20px;
    }

    .wrapper {
        flex-direction: column;
        gap: 25px;
        padding-bottom: 35px;
    }

    .hours {
        font-size: 16px;
        line-height: 21px;
    }

    .social {
        gap: 40px;
    }

    .map {
        border-radius: 10px;
    }
}
