.CityAndPrice {
    display: flex;
    gap: 1.465vw;
}

.city {
    font-family: var(--text-font-family);
    font-weight: 700;
    font-size: .977vw;
    line-height: 1.221vw;
}

.price {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: .732vw;
    font-family: var(--text-font-family);
    font-size: .879vw;
    line-height: 1.221vw;
    width: 100%;
}

@media screen and (max-width: 1536px) {
    .city, .price {
        font-size: 16px;
        line-height: 21px;
    }
    .CityAndPrice {
        flex-direction: column;
    }
}

@media screen and (max-width: 1279px) {
    .CityAndPrice {
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .city {
        font-size: 18px;
        line-height: 25px;
        white-space: nowrap;
    }

    .price {
        font-size: 16px;
        line-height: 20px;
    }
}