.Navigation {
    display: flex;
    flex-direction: column;
    gap: .977vw;
    width: 100%;
    padding: 5.371vw 0 5.615vw;
}

.link {
    font-family: var(--text-font-family);
    font-size: .781vw;
    line-height: .928vw;
    color: var(--text-main-color);
    text-decoration: none;
    text-transform: uppercase;
    transition: transform .5s linear;
}

.link:hover {
    transform: scale(.95);
}

.active {
    font-family: var(--heading-font-family);
    font-weight: 900;
    font-size: .977vw;
    line-height: 1.367vw;
    text-decoration: none;
    color: var(--text-main-color);
    display: flex;
    gap: .732vw;
    align-items: center;
    pointer-events: none;
}

.active::before {
    content: '';
    background: url(../../../images/navlink-arrow.svg) no-repeat center center;
    background-size: contain;
    width: .732vw;
    height: .732vw;
    animation: from-left .3s ease-in-out;
}

@media screen and (max-width: 1536px) {
    .link {
        font-size: 16px;
        line-height: 21px;
    }

    .active {
        font-size: 16px;
        line-height: 21px;
    }
}

@media screen and (max-width: 1279px) {

    .Navigation {
        gap: 30px;
        padding: 0;
    }

    .link {
        font-size: 18px;
        line-height: 19px;
    }

    .active {
        font-size: 20px;
        line-height: 28px;
    }

    .active:before {
        width: 15px;
        height: 15px;
        margin-left: 10px;
        animation: to-left .3s linear forwards;
    }
}

@keyframes from-left {
    from {
        opacity: 0;
        transform: translateX(-5px);
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes to-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-10px);
    }
}