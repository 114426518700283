.Legal {
    height: 100%;
    padding: 2.930vw 2.197vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
    gap: 10px;
}

.container {
    max-width: 800px;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 2.441vw;
}

h2 {
    font-family: var(--heading-font-family);
    font-weight: 900;
    font-size: 22px;
    line-height: 31px;
    padding-top: 20px;
}

ol {
    list-style-position: inside;
    display: flex;
    flex-direction: column;
    gap: 10px
}

@media screen and (max-width: 1279px) {
    .Legal {
        width: 100%;
        padding: 35px 20px 50px;
    }

    .container {
        max-width: 100%;
        font-size: 12px;
        padding-bottom: 35px;
    }

    h2 {
        font-size: 16px;
        line-height: 22px;
    }
}