.link {
    text-decoration: none;
    color: var(--text-main-color);
    overflow: hidden;
}

.link:hover > .heading:after {
    margin-right: -15px;
}

.link:hover > div > .image {
    transform: scale(1.05);
    overflow: hidden;
}

.wrapper {
    overflow: hidden;
    border-radius: 10px 10px 0 0;
    max-height: 34.668vw;
}

.heading {
    font-family: var(--heading-font-family);
    font-weight: 900;
    font-size: 1.074vw;
    line-height: 1.514vw;
    text-transform: uppercase;
    max-width: 80%;
    min-height: 3.418vw;
    display: flex;
    justify-content: space-between;
    gap: .977vw;
}

.heading::after {
    content: '';
    background: url(../../../images/product-card-arrow.svg) no-repeat center center;
    width: 1.709vw;
    height: 1.709vw;
    background-size: contain;
    transition: margin-right .3s ease-in-out;
    flex-shrink: 0;
}

.image {
    width: 100%;
    display: flex;
    user-select: none;
    transition: transform .3s ease-in-out;
    pointer-events: none;
}

@media screen and (max-width: 1536px) {
    .heading {
        font-size: 16px;
        line-height: 21px;
        max-width: 95%;
        white-space: nowrap;
    }

    .link:hover > .heading:after {
        margin-right: -10px;
    }
}

@media screen and (max-width: 1279px) {
    .heading {
        font-size: 16px;
        line-height: 20px;
        gap: 20px;
        min-height: unset;
        padding-bottom: 10px;
        max-width: 90%;
    }

    .heading::after {
        width: 30px;
        height: 30px;
    }

    .image {
        object-position: 100% 30%;
    }

    .wrapper {
        max-height: 175px;
    }
}