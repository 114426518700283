.CircleText {
    width: 12vw;
    height: 12vw;
    background: url(../../../images/products-circle-text.svg) no-repeat;
    background-size: contain;
    animation: spin 60s linear infinite;
    will-change: transform;
}

@keyframes spin {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(360deg);
    }
}

@media (prefers-reduced-motion: reduce) {
    .CircleText {
        animation: none;
    }
}

@media screen and (max-width: 1279px) {
    .CircleText {
        display: none;
    }
}