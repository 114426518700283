.SectionHeader {
    max-width: 34.180vw;
}

.heading {
    font-family: var(--heading-font-family);
    font-weight: 900;
    font-size: 1.563vw;
    line-height: 2.197vw;
    text-transform: uppercase;
    color: var(--text-main-color);
}

.subheading {
    font-family: var(--text-font-family);
    font-size: .879vw;
    line-height: 1.221vw;
    color: var(--text-main-color);
    padding-top: 0.977vw;
}

@media screen and (max-width: 1536px) {
    .heading {
        font-size: 25px;
        line-height: 28px;
    }

    .subheading {
        font-size: 16px;
        line-height: 21px;
    }

    .SectionHeader {
        max-width: 500px;
    }
}

@media screen and (max-width: 1279px) {
    .SectionHeader {
        max-width: 100%;
    }

    .heading {
        font-size: 24px;
        line-height: 30px;
    }

    .subheading {
        font-size: 16px;
        line-height: 20px;
        padding-top: 15px;
    }
}