.Products {
    height: 100%;
    padding: 2.930vw 7.324vw 0 2.197vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 4.639vw;
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(7.324vw, 1fr));
    gap: 1.221vw;
}

.container {
    position: absolute;
    top: -2.930vw;
    right: 23vw;
}

@media screen and (max-width: 1279px) {
    .Products {
        width: 100%;
        padding: 40px 20px 50px;
        overflow-y: scroll;
    }

    .grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
        padding-top: 40px;
    }
}