.NotFound {
    height: 100%;
    padding: 0 2.197vw 0 0;
    display: flex;
    gap: 2.441vw;
    align-items: center;
    animation: fade-in 1s linear;
}

.imageContainer {
    width: 24.414vw;
    display: flex;
    flex-direction: column;
    gap: 1.221vw;
    height: 100%;
    flex-shrink: 0;
}

.image {
    width: 100%;
}

.top {
    max-height: 13.916vw;
    border-radius: 0 0 50px 0;
}

.middle {
    border-radius: 0 50px 50px 0;
}

.bottom {
    border-radius: 0 50px 0 0;
    max-height: 14.648vw;
}


.social {
    display: flex;
    gap: 1.465vw;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 1.953vw;
    align-items: center;
    align-self: center;
    width: 100%;
}

.buttonWrapper {
    max-width: 11.963vw;
}

.textWrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    text-align: center;
    font-family: var(--text-font-family);
    color: var(--text-main-color);
    font-size: .879vw;
    line-height: 1.221vw;
    max-width: 29.297vw;
}

.textWrapper p:nth-child(2) {
    padding-bottom: .977vw;
}

.title {
    font-family: var(--heading-font-family);
    color: var(--text-main-color);
    font-weight: 900;
    font-size: 7.813vw;
    line-height: 11.035vw;
}

@media screen and (max-width: 1536px) {
    .buttonWrapper {
        max-width: 100%;
    }

    .textWrapper {
        font-size: 16px;
        line-height: 21px;
    }
}

@media screen and (max-width: 1279px) {
    .NotFound {
        width: 100%;
        padding: 35px 20px 50px;
    }

    .container {
        gap: 35px;
    }

    .imageContainer {
        display: none;
    }

    .buttonWrapper {
        min-width: 170px;
        max-width: unset;
    }

    .title {
        font-size: 68px;
        line-height: 78px;
        padding-bottom: 10px;
    }

    .textWrapper {
        font-size: 16px;
        line-height: 21px;
        max-width: 100%;
    }

    .textWrapper p:nth-child(2) {
        padding-bottom: 35px;
    }

    .social {
        gap: 35px;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}