.Button {
    padding: .586vw 1.709vw;
    border-radius: var(--button-border-radius);
    font-family: var(--text-font-family);
    text-transform: uppercase;
    font-size: .879vw;
    line-height: 1.221vw;
    cursor: pointer;
    width: 100%;
    white-space: nowrap;
    user-select: none;
    transition: opacity .3s ease-out, transform .3s ease-in-out;
}

.Button:active {
    transform: scale(.98);
}

.Button:disabled {
    pointer-events: none;
    transform: scale(.98);
}

.secondary:hover {
    opacity: .3;
}

.primary:hover {
    opacity: .85;
}


.secondary {
    border: 1px solid var(--text-main-color);
    background-color: transparent;
}

.primary {
    background-color: var(--primary-bg-color);
    border: 1px solid var(--primary-bg-color);
}

:disabled {
    opacity: .4;
    font-weight: normal;
}

.secondary:disabled {
    border-color: rgba(0, 0, 0, .4);
}

@media screen and (max-width: 1536px) {
    .Button {
        font-size: 14px;
        line-height: 16px;
    }
}

@media screen and (max-width: 1279px) {
    .Button {
        font-size: 15px;
        line-height: 19px;
        padding: 10px;
        color: var(--text-main-color)
    }
}

@media screen and (max-width: 380px) {
    .Button {
        font-size: 13px;
        line-height: 17px;
        padding: 8px 5px;
    }
}