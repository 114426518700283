.Header {
    width: var(--header-width);
    min-width: var(--header-width);
    height: 100%;
    padding: 2.930vw 3.418vw 0 2.197vw;
    background-color: var(--header-bg-color);
    display: flex;
    flex-direction: column;
    user-select: none;
    justify-content: space-between;
    z-index: 999;
    align-self: flex-start;
}

.contacts {
    font-family: var(--text-font-family);
    font-size: .781vw;
    line-height: 1.074vw;
    display: flex;
    gap: .732vw;
    flex-direction: column;
    padding-bottom: 4.883vw;
}

.link {
    text-decoration: none;
    color: var(--text-font-family);
    display: flex;
    align-items: center;
    gap: .732vw;
    transition: opacity .3s ease-out;
}

.link:last-of-type {
    padding-bottom: .977vw;
}

.link:hover {
    opacity: .5;
}

.phone::before {
    content: '';
    background: url(../../../images/header-phone.svg) no-repeat center center;
    width: .977vw;
    height: .977vw;
    background-size: contain;
    flex-shrink: 0;
}

.email::before {
    content: '';
    background: url(../../../images/header-email.svg) no-repeat center center;
    width: .977vw;
    height: .977vw;
    background-size: contain;
    flex-shrink: 0;
}

.hours::before {
    content: '';
    background: url(../../../images/header-hours.svg) no-repeat center center;
    width: .977vw;
    height: .977vw;
    background-size: contain;
    flex-shrink: 0;
}

.logo {
    background: url(../../../images/made-in-russia-logo.svg) center center no-repeat;
    background-size: contain;
    width: 6.689vw;
    height: 5.859vw;
    flex-shrink: 0;
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
}

@media screen and (max-width: 1536px) {
    .contacts {
        font-size: 16px;
        line-height: 21px;
    }

    .Header {
        width: 365px;
    }
}

@media screen and (max-width: 1279px) {
    .Header {
        position: fixed;
        top: 69px;
        left: -100%;
        width: 100%;
        min-width: 100%;
        height: calc(100% - 69px);
        padding: 90px 20px 0;
        transition: left .3s ease-in-out;
        justify-content: space-between;
    }

    .contacts {
        font-size: 16px;
        line-height: 22px;
        gap: 15px;
        padding: 30px 0 50px;
    }

    .link {
        gap: 15px;
    }

    .email:before, .phone:before, .hours:before {
        width: 20px;
        height: 20px;
    }

    .logo {
        width: 95px;
        height: 50px;
    }

    .visible {
        left: 0;
    }
}