.Gallery {
    display: flex;
    gap: 1.221vw;
    width: 29.297vw;
    height: 100%;
    flex-shrink: 0;
    user-select: none;
    pointer-events: none;
}

.image {
    width: 100%;
    object-fit: cover;
}

.top {
    border-radius: 0 0 50px 0;
    max-height: 14.404vw;
}

.bottom {
    border-radius: 0 50px 0 0;
    height: 100%;
}

.normal {
    flex-direction: column;
}

.reverse {
    flex-direction: column-reverse;
}

.topReverse {
    border-radius: 0 0 50px 0;
    height: 100%;
}

.bottomReverse {
    max-height: 14.404vw;
    border-radius: 0 50px 0 0;
}

@media screen and (max-width: 1279px) {
    .Gallery {
        width: calc(100% + 40px);
        max-height: 190px;
        gap: 10px;
        margin-left: -20px;
    }

    .normal {
        flex-direction: row;
    }

    .reverse {
        flex-direction: row-reverse;
    }

    .top {
        max-width: 95px;
        max-height: 100%;
        object-position: 60% 100%;
    }

    .bottom {
        border-radius: 0 0 0 50px;
        object-position: 100% 20%;
    }

    .topReverse {
        max-height: 100%;
        object-position: 60% 100%;
    }

    .bottomReverse {
        max-width: 95px;
        max-height: 100%;
        border-radius: 0 0 0 50px;
        object-position: center;
    }
}