.Home {
    display: flex;
    flex-direction: row;
    height: 100%;
    user-select: none;
    animation: fade-in 1s linear;
}

.about {
    width: 58.105vw;
    background: url(../../images/home-main-bg.jpg) center right no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.wrapper {
    max-width: 21.973vw;
    padding-bottom: 2.441vw;
}

.buttonWrapper {
    max-width: 11.719vw;
}

.heading {
    font-family: var(--heading-font-family);
    font-weight: 900;
    font-size: 3.613vw;
    line-height: 4.102vw;
    text-transform: uppercase;
    color: var(--text-secondary-color);
}

.subheading {
    font-family: var(--text-font-family);
    color: var(--text-secondary-color);
    font-size: .977vw;
    line-height: 1.318vw;
    padding: .488vw 0 2.441vw;
}

.accent {
    width: 17.090vw;
    background: url(../../images/home-accent-bg.jpg) center left no-repeat;
    background-size: cover;
    margin-left: .732vw;
}

.social {
    max-width: 7.324vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.465vw;
    justify-content: center;
    align-items: center;
}

.social::before {
    content: '';
    background-color: var(--text-main-color);
    height: 15.869vw;
    width: 2px;
    margin-bottom: 0.977vw;
}

.social::after {
    content: '';
    background-color: var(--text-main-color);
    height: 15.869vw;
    width: 2px;
    margin-top: 0.977vw;
}

@media screen and (max-width: 1536px) {
    .subheading {
        font-size: 16px;
        line-height: 21px;
    }

    .accent {
        flex-shrink: 0;
    }
}

@media screen and (max-width: 1279px) {

    .Home {
        width: 100%;
        height: 100%;
        user-select: none;
        flex-direction: column;
    }

    .wrapper {
        display: grid;
        align-content: center;
        grid-column: 2/4;
        width: 100%;
        max-width: 100%;
        padding-bottom: 50px;
    }

    .buttonWrapper {
        max-width: 170px;
    }

    .heading {
        font-size: 35px;
        line-height: 41px;
    }

    .subheading {
        font-size: 16px;
        line-height: 20px;
        padding: 10px 0 20px;
    }

    .about {
        width: 100%;
        height: calc(80% - 10px);
        background: url(../../images/home-main-bg-mobile.jpg) center bottom no-repeat;
        background-size: cover;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        padding: 0 20px;
    }

    .social {
        display: flex;
        height: 50px;
        gap: 25px;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        width: 100%;
        max-width: 100%;
        flex-direction: row;
    }

    .social:before {
        content: '';
        background-color: var(--text-main-color);
        margin-right: 30px;
        width: 100%;
        height: 1px;
    }

    .social:after {
        content: '';
        background-color: var(--text-main-color);
        margin-left: 30px;
        width: 100%;
        height: 1px;
    }

    .accent {
        width: 100%;
        height: calc(20% - 50px);
        background: url(../../images/home-accent-bg-mobile.jpg) center top no-repeat;
        background-size: cover;
        margin-top: 10px;
        margin-left: 0;
    }
}

@media screen and (max-width: 360px) {
    .heading {
        font-size: 26px;
        line-height: 33px;
    }

    .subheading {
        font-size: 14px;
        line-height: 18px;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}