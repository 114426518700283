.Works {
    height: 100%;
    padding: 0 2.197vw;
    display: grid;
    gap: 1.221vw;
    grid-template-columns: 1fr .5fr .5fr 1fr 1fr 1fr;
    grid-template-rows: 7.324vw .75fr 1fr .5fr 2.441vw .5fr 1.221vw 0;
    grid-template-areas:
    "title title title title three four"
    "one one two two three four"
    "five six six six seven seven"
    "eight nine nine eleven twelve twelve"
    "eight nine nine eleven twelve twelve"
    "eight ten ten eleven twelve twelve"
    "eight ten ten eleven twelve twelve"
    "eight ten ten eleven . .";
}

.title {
    grid-area: title;
    align-self: start;
    padding-top: 2.930vw;
}

.one {
    grid-area: one;
    border-radius: 0 0 10px 0;
}

.two {
    grid-area: two;
    border-radius: 0 0 10px 10px;
}

.three {
    grid-area: three;
    border-radius: 0 0 10px 10px;
}

.four {
    grid-area: four;
    border-radius: 0 0 0 10px;
}

.five {
    grid-area: five;
    border-radius: 0 10px 10px 0;
}

.six {
    grid-area: six;
    border-radius: 10px;
}

.twelve {
    grid-area: seven;
    border-radius: 10px 0 0 10px;
}

.eight {
    grid-area: eight;
    border-radius: 0 10px 0 0;
}

.nine {
    grid-area: nine;
    border-radius: 10px;
}

.ten {
    grid-area: ten;
    border-radius: 10px 10px 0 0;
}

.eleven {
    grid-area: eleven;
    border-radius: 10px 10px 0 0;
}

.seven {
    grid-area: twelve;
    border-radius: 10px 0 0 0;
}


img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

@media screen and (max-width: 1279px) {
    .Works {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: scroll;
        padding: 35px 20px 50px;
    }

    .title {
        padding-top: 0;
    }

    .Works article {
        padding-bottom: 25px;
    }

    .one {
        grid-area: one;
        border-radius: 0 0 10px 10px;
    }

    .twelve {
        grid-area: seven;
        border-radius: 10px 10px 0 0;
    }

    .two, .three, .four, .five, .six, .seven, .eight, .nine, .ten, .eleven {
        border-radius: 10px;
    }
}

